jQuery(document).ready(function ($) {
  const prefixes = ['m', 'f', 'a']; // Prefixes for participant groups

  // Loop through each participant button
  $('.participants-btn').on('click', function () {
    // Get the ID of the clicked button
    const id = $(this).attr('id');

    // Identify the prefix based on the ID
    const prefix = prefixes.find((p) => id.startsWith(`${p}-`)); // Find the prefix that matches

    // If a matching prefix is found, apply the logic
    if (prefix) {
      // Get the current number of participants for the corresponding group
      let num = parseInt($(`.${prefix}-participants-number input`).val());

      // Check if this is the "add participant" button (e.g., 'm-add-participant')
      if (`${prefix}-add-participant` === id) {
        num++;
        if (num < 12) {
          // Ensure the button is visible if the number is less than 12
          $(`#${prefix}-remove-participant`).removeClass('hidden');
        } else {
          num = 12; // Cap the number at 12 participants
          $(this).addClass('hidden'); // Hide the "add" button if 12 is reached
        }
      } else {
        num--;
        if (num > 1) {
          $(`#${prefix}-add-participant`).removeClass('hidden');
        } else {
          num = 1; // Ensure the number doesn't go below 1
          $(this).addClass('hidden'); // Hide the "remove" button if 1 participant
        }
      }

      // Update the input field with the new participant count
      $(`.${prefix}-participants-number input`).val(num);

      // Trigger the change event to update the total number of participants
      $(`.${prefix}-participants-number input`).trigger('change');
    }
  });
});
